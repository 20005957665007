<template>
  <label class="switchBtn">
    <input
        type="checkbox"
        :value="modelValue"
        :checked="modelValue"
        @change="onChanged"
    >
    <div class="slide round"></div>
  </label>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "SwitchComponent",

  emits: ['update:modelValue'],

  props: {
    modelValue: Boolean,
    required: {
      type: Boolean,
      default: false
    },

  },

  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.target.checked);
    }

    return {
      onChanged
    }
  }
})

</script>

<style scoped>
.switchBtn {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 32px;
}

.switchBtn input {display:none;}

.slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--complementary-400);
  -webkit-transition: .4s;
  transition: .4s;
  padding: 8px;
  color: #fff;
}

.slide:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  top: 4px;
  left: 5px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slide {
  background-color: var(--primary);
  padding-left: 40px;
}

input:checked + .slide:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.slide.round { border-radius: 34px; }
.slide.round:before { border-radius: 50%; }
</style>