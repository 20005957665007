<template>
  <section class="plato">
    <div class="plato__image" :style="backgroundPlaceholderItem(product)" >
    </div>
    <div class="plato__information">
      <div class="plato__text">
        <h3>{{product.name}}</h3>
        <p>{{product.description}}</p>
      </div>
      <div class="plato__prices-btn">
        <div class="plato__prices">
          <p class="discount" v-if="product.has_discount">S/.{{product.promo_price}}</p>
          <p class="anterior" v-if="product.has_discount">S/.{{product.price.toFixed(2)}}</p>
          <p v-else>S/.{{product.price.toFixed(2)}}</p>
        </div>
      </div>
    </div>
    <div class="plato__discount" v-if="product.has_discount">
      <p>{{parseFloat((parseFloat(product.promo_price) - parseFloat(product.price))/(parseFloat(product.price))*100).toFixed(0)}}%</p>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ProductoCarta',
  components: {},
  props: ['product'],
  methods: {

    backgroundPlaceholderItem() {
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(this.product.img)
        if (!this.product.is_available) {
          return {background:`url(${this.product.img}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${this.product.img}) center center`,'background-size':'cover' }
      } catch (error) {
        if (!this.product.is_available) {
          return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover' }
      }
    },
  }
}
</script>

<style scoped>
  .plato{
    position: relative;
    display: flex;
    gap: 10px;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    width: 100%;
    max-width: 400px;
    overflow: hidden;
    background: #F6F6F6;
    cursor: pointer;
    height: 160px;
    padding: 10px;
    transition: all .5s ease-in-out;
  }

  .plato__image{
    min-width: 120px;
    height: 100%;
    border-radius: 8px;
    -o-object-fit: cover;
    object-fit: cover;
    display: block
  }

  .plato__information {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: -moz-max-content
  }

  .plato__information p{
    font-size: 14px;
  }

  .plato__text{
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    height: 100px
  }

  .plato__text::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -40px;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(246,246,246,0) 0%, rgba(246,246,246,1) 100%);
  }

  .plato__text h3{ font-size: 18px; }

  .plato__prices-btn{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
  }

  .plato__prices-btn button{ display: none; }

  .plato__prices{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  }

  .plato__prices p{
    color: var(--text-black);
    font-size: 20px;
    font-weight: var(--bold);
    text-decoration: none
  }

  .plato__prices .anterior{
    color: #858585;
    font-size: 16px;
    margin: 0;
    text-decoration: line-through
  }

  .plato__prices .discount{
    color: #c40e0e;
    font-size: 20px;
    font-weight: var(--regular);
    text-decoration: none
  }

  .plato__discount{
    position: absolute;
    top: 5px;
    left: 0;
    padding: 5px 10px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: var(--primary);
  }

  .plato__discount p{
    color: var(--text-white);
    font-size: 18px;
    font-weight: var(--bold);
  }


  @media only screen and (min-width: 768px){
    .plato{
      max-width: 500px;
      height: 200px;
      padding: 0;
    }

    .plato__information {
      padding: 10px 10px 10px 0
    }

    .plato__image{
      min-width: 200px;
      border-radius: 0;
    }

    .plato__text{
      height: 140px;
      -webkit-line-clamp: 4;
    }

    .plato__prices{ margin-left: initial; }

    .plato__prices-btn{ justify-content: space-between; }

    .plato__prices-btn button{ display: block; }

    .plato__text h3{ font-size: 22px; }
  }
  </style>