<template>
  <article class="carta__card" style="background-image: url({{category.img}})" data-aos="fade-up">
    <div class="carta__nombre">
      <h3>{{category.name}}</h3>
    </div>
    <div class="carta__hover">
      <h3>{{category.name}}</h3>
      <ButtonSecundary>{{ $t('buttons.txt_ordena_ahora') }}</ButtonSecundary>
    </div>
  </article>
</template>

<script>
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary.vue";

export default {
  name: 'CategoriasCarta',
  components: {ButtonSecundary},
  props: ['category'],
}

</script>

<style scoped>
  .carta__card{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
    box-shadow: var(--box-shadow)
  }

  .carta__card h3{
    color: var(--text-white);
    font-family: var(--headings-font-family);
    font-size: 28px;
    text-align: center
  }

  .carta__nombre{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    box-shadow: inset 0 0 400px rgba(122, 8, 12, .85);
    opacity: 1;
    transition: opacity .3s linear
  }

  .carta__nombre h3{
    text-align: left;
    font-family: var(--headings-font-family);
    font-size: 23px
  }

  .carta__hover{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transform: translateY(80%);
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: inset 0 0 400px rgba(122, 8, 12, .85);
    transition: transform .3s linear;
  }

  .carta__hover::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-image: url("../../assets/images-new/vectores_carta_home_card.png");
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .carta__card:hover .carta__nombre {
    top: -100%;
    opacity: 0
  }

  .carta__card:hover .carta__hover {
    opacity: 1;
    transform: translateY(0)
  }

  .carta__card:not(:hover) .carta__hover {
    transform: translateY(100%);
    opacity: 1
  }

  .carta__hover button {
    text-transform: uppercase
  }

  @media only screen and (min-width: 390px){
    .carta__card{
      height: 180px;
    }
  }

  @media only screen and (min-width: 425px){
    .carta__card{
      height: 250px;
    }
  }

  @media only screen and (min-width: 768px){
    .carta__card{ width: 48%; }
  }

  @media only screen and (min-width: 1024px){
    .carta__card{ width: 425px; }

    .carta__nombre h3 { font-size: 28px }
  }
</style>