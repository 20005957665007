<template>
  <div class="rastreo" style="overflow: hidden">
    <CarouselRastreo
        :info_carousel="info_carousel_rastreo"
    >
    </CarouselRastreo>
    <div class="rastreo__pedido">
      <OrderInfoComponent
          :from_tracking=true
          :allow_back=false
      >
      </OrderInfoComponent>
    </div>
    <TiendaAppModal v-if="showTiendaAppModal"
        :android="tiendaAppModal.android"
        :iPhone="tiendaAppModal.iPhone"
        :message="tiendaAppModal.message"
        :dismiss="tiendaAppModal.dismiss"
        @dismissModal="showModal"
        @androidEvent="openLink('android')"
        @iPhoneEvent="openLink('iOS')"
    >
    </TiendaAppModal>
  </div>
</template>

<script>
import {defineAsyncComponent} from "vue";
import OrderInfoComponent from "@/components/OrderInfoComponent";
import CarouselRastreo from "@/components/CarouselRastreo.vue";
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";

export default {
  name: "RastreoPedido.vue",

  components: {
    OrderInfoComponent,
    TiendaAppModal: defineAsyncComponent(() => import("../components/MODALS/TiendaAppModal")),
    CarouselRastreo
},

  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: `Seguimiento de pedido`,
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Seguimiento de pedido en ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },

  data () {
    return {

      tiendaAppModal: {
        android: 'Android',
        iPhone: 'iPhone',
        message: 'Selecciona tu dispositivo',
        dismiss: 'Regresar'
      },
      showTiendaAppModal: false,
      info_carousel_rastreo: [
        {
          imgMobile: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Amkha+Per%C3%BA/ux_web_files/banner_1_rastreo_mobile.webp',
          title: '¡Atención express!',
          paragraph: 'Los pedidos en línea son procesados con la mayor rapidez posible. ¡Ayúdanos a servirte mejor!',
          imgDesktop: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Amkha+Per%C3%BA/ux_web_files/banner_1_rastreo_desktop.webp',
        },
        {
          imgMobile: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Amkha+Per%C3%BA/ux_web_files/banner_2_rastreo_mobile.webp',
          title: 'Compra al instante',
          paragraph: 'Pide tus productos favoritos de una manera fácil y rápida.',
          imgDesktop: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Amkha+Per%C3%BA/ux_web_files/banner_2_rastreo_desktop.webp',
        },
        {
          imgMobile: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Amkha+Per%C3%BA/ux_web_files/banner_3_rastreo_mobile.webp',
          title: '¡Acumula tus Barripuntos!',
          paragraph: 'Barrilito Express, siempre premiará tu preferencia ¡Acumula puntos y canjéalos!',
          imgDesktop: 'https://quickeat.s3.us-west-2.amazonaws.com/local/Amkha+Per%C3%BA/ux_web_files/banner_3_rastreo_desktop.webp',
        },
      ],

    }
  },
  async  mounted () {

  },
  methods: {
    openLink (to) {
      switch (to) {
        case 'whatsapp':
          this.$gtag.event('Whatsapp', { click_desde: 'Rastreo-Pedidos' })
          break;
        case 'android':
          this.$gtag.event('Descargar App', { android: 'Rastreo-Pedidos'})
          window.open('https://play.google.com/store/apps/details?id=cquickeat.dondewalter', '_blank')
          setTimeout(() => {
            this.showTiendaAppModal=false
          }, 100)
          break;
        case 'iOS':
          this.$gtag.event('Descargar App', { iPhone: 'Rastreo-Pedidos' })
          window.open('https://apps.apple.com/es/app/donde-walter/id1573946954', '_blank')
          setTimeout(() => {
            this.showTiendaAppModal=false
          }, 100)
          break
      }
    },
    showModal() {
      this.showTiendaAppModal = !this.showTiendaAppModal
    },
  },
  computed: {
  },
  watch: {
  },
}
</script>
<style scoped>
  .rastreo__pedido{
    padding: 20px 16px;
  }

  @media only screen and (min-width: 768px){
    .rastreo{
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      padding: 50px 32px;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media only screen and (min-width: 1024px){

  }
</style>
