<template>
  <div class="wrapper_ui_kit">
    <div class="buttons">
      <ButtonPrimary>
        Button primary
      </ButtonPrimary>
      <ButtonPrimary>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 8H1C0.716667 8 0.479167 7.90417 0.2875 7.7125C0.0958333 7.52083 0 7.28333 0 7C0 6.71667 0.0958333 6.47917 0.2875 6.2875C0.479167 6.09583 0.716667 6 1 6H6V1C6 0.716667 6.09583 0.479167 6.2875 0.2875C6.47917 0.0958333 6.71667 0 7 0C7.28333 0 7.52083 0.0958333 7.7125 0.2875C7.90417 0.479167 8 0.716667 8 1V6H13C13.2833 6 13.5208 6.09583 13.7125 6.2875C13.9042 6.47917 14 6.71667 14 7C14 7.28333 13.9042 7.52083 13.7125 7.7125C13.5208 7.90417 13.2833 8 13 8H8V13C8 13.2833 7.90417 13.5208 7.7125 13.7125C7.52083 13.9042 7.28333 14 7 14C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13V8Z" fill="currentColor"/>
        </svg>
        Button primary
      </ButtonPrimary>
      <ButtonPrimary :disabled="true">
        Button primary
      </ButtonPrimary>
    </div>
    <div class="buttons">
      <ButtonSecundary>
        Button secundary
      </ButtonSecundary>
      <ButtonSecundary>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 8H1C0.716667 8 0.479167 7.90417 0.2875 7.7125C0.0958333 7.52083 0 7.28333 0 7C0 6.71667 0.0958333 6.47917 0.2875 6.2875C0.479167 6.09583 0.716667 6 1 6H6V1C6 0.716667 6.09583 0.479167 6.2875 0.2875C6.47917 0.0958333 6.71667 0 7 0C7.28333 0 7.52083 0.0958333 7.7125 0.2875C7.90417 0.479167 8 0.716667 8 1V6H13C13.2833 6 13.5208 6.09583 13.7125 6.2875C13.9042 6.47917 14 6.71667 14 7C14 7.28333 13.9042 7.52083 13.7125 7.7125C13.5208 7.90417 13.2833 8 13 8H8V13C8 13.2833 7.90417 13.5208 7.7125 13.7125C7.52083 13.9042 7.28333 14 7 14C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13V8Z" fill="currentColor"/>
        </svg>
        Button secundary
      </ButtonSecundary>
      <ButtonSecundary :disabled="true">
        Button secundary
      </ButtonSecundary>
    </div>
    <div class="buttons">
      <ButtonOutline>
        Button outline
      </ButtonOutline>
      <ButtonOutline>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 8H1C0.716667 8 0.479167 7.90417 0.2875 7.7125C0.0958333 7.52083 0 7.28333 0 7C0 6.71667 0.0958333 6.47917 0.2875 6.2875C0.479167 6.09583 0.716667 6 1 6H6V1C6 0.716667 6.09583 0.479167 6.2875 0.2875C6.47917 0.0958333 6.71667 0 7 0C7.28333 0 7.52083 0.0958333 7.7125 0.2875C7.90417 0.479167 8 0.716667 8 1V6H13C13.2833 6 13.5208 6.09583 13.7125 6.2875C13.9042 6.47917 14 6.71667 14 7C14 7.28333 13.9042 7.52083 13.7125 7.7125C13.5208 7.90417 13.2833 8 13 8H8V13C8 13.2833 7.90417 13.5208 7.7125 13.7125C7.52083 13.9042 7.28333 14 7 14C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13V8Z" fill="currentColor"/>
        </svg>
        Button outline
      </ButtonOutline>
      <ButtonOutline :disabled="true">
        Button outline
      </ButtonOutline>
    </div>
    <div class="buttons">
      <ButtonTexto>
        Button texto
      </ButtonTexto>
      <ButtonTexto>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 8H1C0.716667 8 0.479167 7.90417 0.2875 7.7125C0.0958333 7.52083 0 7.28333 0 7C0 6.71667 0.0958333 6.47917 0.2875 6.2875C0.479167 6.09583 0.716667 6 1 6H6V1C6 0.716667 6.09583 0.479167 6.2875 0.2875C6.47917 0.0958333 6.71667 0 7 0C7.28333 0 7.52083 0.0958333 7.7125 0.2875C7.90417 0.479167 8 0.716667 8 1V6H13C13.2833 6 13.5208 6.09583 13.7125 6.2875C13.9042 6.47917 14 6.71667 14 7C14 7.28333 13.9042 7.52083 13.7125 7.7125C13.5208 7.90417 13.2833 8 13 8H8V13C8 13.2833 7.90417 13.5208 7.7125 13.7125C7.52083 13.9042 7.28333 14 7 14C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13V8Z" fill="currentColor"/>
        </svg>
        Button texto
      </ButtonTexto>
      <ButtonTexto :disabled="true">
        Button texto
      </ButtonTexto>
    </div>
    <div class="buttons">
      <ButtonDanger>
        Button danger
      </ButtonDanger>
      <ButtonDanger>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 8H1C0.716667 8 0.479167 7.90417 0.2875 7.7125C0.0958333 7.52083 0 7.28333 0 7C0 6.71667 0.0958333 6.47917 0.2875 6.2875C0.479167 6.09583 0.716667 6 1 6H6V1C6 0.716667 6.09583 0.479167 6.2875 0.2875C6.47917 0.0958333 6.71667 0 7 0C7.28333 0 7.52083 0.0958333 7.7125 0.2875C7.90417 0.479167 8 0.716667 8 1V6H13C13.2833 6 13.5208 6.09583 13.7125 6.2875C13.9042 6.47917 14 6.71667 14 7C14 7.28333 13.9042 7.52083 13.7125 7.7125C13.5208 7.90417 13.2833 8 13 8H8V13C8 13.2833 7.90417 13.5208 7.7125 13.7125C7.52083 13.9042 7.28333 14 7 14C6.71667 14 6.47917 13.9042 6.2875 13.7125C6.09583 13.5208 6 13.2833 6 13V8Z" fill="currentColor"/>
        </svg>
        Button danger
      </ButtonDanger>
      <ButtonDanger :disabled="true">
        Button danger
      </ButtonDanger>
    </div>
    <CheckboxComponent
        :id="'check_1'"
        v-model="check"
    >
      Soy un checkbox componente
    </CheckboxComponent>
    <InputNumberComponent
        :placeholder="'Teléfono'"
    />
  </div>
</template>

<script setup>
  import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
  import ButtonSecundary from "@/components/new/buttons/ButtonSecundary.vue";
  import ButtonOutline from "@/components/new/buttons/ButtonOutline.vue";
  import ButtonTexto from "@/components/new/buttons/ButtonTexto.vue";
  import ButtonDanger from "@/components/new/buttons/ButtonDanger.vue";
  import CheckboxComponent from "@/components/new/inputs/CheckboxComponent.vue";
  import {ref} from "vue";
  import InputNumberComponent from "@/components/new/inputs/InputNumberComponent";

  let check = ref(false);
</script>

<style scoped>
  .wrapper_ui_kit{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .buttons{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
</style>