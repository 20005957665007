<template>
  <div class="sub_container_beneficios">
    <div
      class="item_cupon"
      v-for="(item,index) in beneficios"
      :key="index"
    >
      <div class="img_cupon"
        @click="openModal(item)"
        :style="{background:`url(${item.img}) center center`,'background-size':'cover'}"
      >
      </div>
      <div class="body_cupon">
        <div class="cupon_information">
          <p
            class="cupon"
            @click="openModal(item)"
          >{{item.title}}</p>
          <p
            class="expira"
            @click="openModal(item)"
          >{{ $t('perfilmisbeneficiosbeneficios.txt_expira') }} {{getFecha(item)}}</p>
        </div>
        <ButtonPrimary
            type="button"
            v-if="!item.cod_coupon"
            :disabled="user.points < item.points && !fromPaymentScreen"
            class="points"
            @click="openModal(item)"
        >{{item.points}} {{ $t('ordeinfocomponent.txt_puntos') }}
        </ButtonPrimary>
        <div class="code" v-if="item.cod_coupon">
          <span
                      class="points"
                      @click="copy(item.cod_coupon)"
                      style="cursor:pointer"
                  >{{item.cod_coupon}}</span>
          <span
              class="copy"
              @click="copy(item.cod_coupon)"
              style="cursor:pointer"
          >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="24"
                height="24"
                viewBox="0 0 48 48"
            >
    <defs>
      <clipPath id="clip-Icono_copiar">
        <rect
            width="48"
            height="48"
        />
      </clipPath>
    </defs>
    <g
        id="Icono_copiar"
        data-name="Icono copiar"
        clip-path="url(#clip-Icono_copiar)"
    >
      <g
          id="Grupo_2978"
          data-name="Grupo 2978"
          transform="translate(151.608 -1070.485)"
      >
        <g
            id="Grupo_2189"
            data-name="Grupo 2189"
            transform="translate(-144.046 1073.511)"
        >
          <path
              id="Trazado_4925"
              data-name="Trazado 4925"
              d="M71.636,85.4H51.991a3.343,3.343,0,0,0-3.341,3.341v28.187a3.343,3.343,0,0,0,3.341,3.341H71.636a3.343,3.343,0,0,0,3.341-3.341V88.741A3.354,3.354,0,0,0,71.636,85.4Zm1,31.519a1,1,0,0,1-1,1H51.982a1,1,0,0,1-1-1V88.741a1,1,0,0,1,1-1H71.627a1,1,0,0,1,1,1Z"
              transform="translate(-48.65 -78.009)"
          />
          <path
              id="Trazado_4926"
              data-name="Trazado 4926"
              d="M158.435,0H138.79a3.343,3.343,0,0,0-3.34,3.341,1.168,1.168,0,0,0,2.337,0,1,1,0,0,1,1-1h19.645a1,1,0,0,1,1,1V31.527a1,1,0,0,1-1,1,1.168,1.168,0,1,0,0,2.337,3.343,3.343,0,0,0,3.341-3.341V3.341A3.343,3.343,0,0,0,158.435,0Z"
              transform="translate(-127.938 0)"
          />
        </g>
      </g>
    </g>
  </svg>
        </span>
        </div>
      </div>
    </div>
    <div class="loading" v-if="loadingCoupon" >
      <p>{{ $t('forgotpassword.txt_cargando_dat') }}</p>
    </div>
    <div class="loading" v-if="!loadingCoupon&&beneficios.length===0" >
      <p>{{ $t('perfilmisbeneficiosbeneficios.txt_sin_cupones') }}</p>
    </div>
    <ModalSinPuntos
      v-if="showModalSinPuntos"
      @close="showModalSinPuntos=false"
    ></ModalSinPuntos>
    <transition name="slide-fade">
      <TwoButtonGenericModal
          v-if="showModalConfirm"
          :title="this.$t('perfilmisbeneficiosbeneficios.txt_estimado_cliente')"
          :content="this.$t('perfilmisbeneficiosbeneficios.txt_felicitaciones_desea_canjear_sus_puntos')"
          :accept="this.$t('perfilmisbeneficiosbeneficios.txt_si_canjear_mi_premio')"
          :cancel="this.$t('perfil.txt_no_regresar')"
          @close_modal="showModalConfirm=false"
          @accepted_modal="activarCupon"
      >
      </TwoButtonGenericModal>
    </transition>
  </div>
</template>

<script>
import CuponServices from "@/services/cupon-services"
import moment from 'moment'
import {defineAsyncComponent} from "vue";
import TwoButtonGenericModal from "@/components/MODALS/TwoButtonGenericModal";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary.vue";
import {mapState} from "vuex";

export default {
  emits: ['copy'],
  props: {
    loadPointsCircle: Function,
    puntos: Number,
    fromPaymentScreen: Boolean,
  },
  components: {
    TwoButtonGenericModal,
    ModalSinPuntos: defineAsyncComponent(() => import("./PerfilMisBeneficiosMODALSinPuntos")),
    ButtonPrimary
},
  computed: {
    ...mapState(['user'])
  },

  data () {
    return {
      loading: false,
      loadingCoupon:false,
      showModalSinPuntos: false,
      showModalConfirm: false,
      selectCupon: '',
      type_discount: {
        '0': 'monto',
        '1': 'porcentaje',
        '2': 'delivery',
        '3': 'producto_gratis',
        '4': 'producto_parcial',
        '5': 'producto_porcentual'
      },
      beneficios: [
      ],
    }
  },
  async created () {
    try {
      this.loadingCoupon=true
      await this.loadListCoupons()
    } catch (error) {
      console.log(error)
    }finally{
      this.loadingCoupon=false
    }
  },
  methods: {
    async loadListCoupons () {
      this.beneficios = []
      let response = await CuponServices.listaDeCupones()
      if (response.data.success) {
        response.data.data = response.data.data.sort(function (a, b) {
          if (a.id > b.id) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 0;
        });
        this.beneficios = await response.data.data.filter(cupon => {
          let now = new Date()
          let start = new Date(cupon.date_start)
          let end = new Date(cupon.date_end)
          if (cupon.expire_coupon.length > 0) {
            end = new Date(cupon.expire_coupon)

          }
          if (cupon.is_available && (end >= now) && (start <= now)) {
            return true
          }
        })
      }

    },
    copy (coupon) {
      let dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = coupon;
      dummy.select();

      let res = document.execCommand("copy");
      if (res) {
        this.$toast.success(this.$t('perfilmisbeneficiosbeneficios.txt_se_copio_el_codigo_del_cupon'))
      }
      document.body.removeChild(dummy);
      this.$emit('copy',coupon)
    },
    getFecha (cupon) {
      if (cupon.cod_coupon.length > 0) {
        return moment(cupon.expire_coupon).locale('es').format("DD/MM/YYYY, hh:mm a");
      } else {
        return moment(cupon.date_end).locale('es').format("DD/MM/YYYY, hh:mm a");
      }
    },
    openModal (cupon) {
      if (this.puntos > 0) {
        if (cupon.cod_coupon.length < 3 && !this.loading) {
          this.selectCupon = cupon
          this.showModalConfirm = true
        }
      } else {
        this.showModalSinPuntos = true
      }
    },
    async activarCupon () {
      this.showModalConfirm = false
      try {
        this.loading = true
        let response = await CuponServices.activarUnCupon(this.selectCupon.id)
        console.log(response)
        if (response.data.success) {
          this.$toast.success(this.$t('perfilmisbeneficiosbeneficios.txt_se_activo_cupon'))
          await this.loadListCoupons()
          await this.loadPointsCircle()
        } else {
          this.$toast.error(response.data.message)
        }

      } catch (error) {
        this.$toast.error(this.$t('perfilmisbeneficiosbeneficios.txt_ocurrio_un_error_al'))
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
  .loading{
    text-align: center;
    font-size: 30px;
    align-items: center;
    min-height: 100px;
  }

  .loading p{
    margin-top: 20px;
    font-size: 28px;
    font-weight: var(--bold);
  }

  .sub_container_beneficios{
    height: 100%;
    max-height: 700px;
    overflow-y: auto;
  }

  .item_cupon{
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid var(--complementary-400);
  }

  .item_cupon:last-child{
    border-bottom: 0;
  }

  .img_cupon{
    min-width: 72px;
    min-height: 72px;
    border: 1px solid var(--complementary-400);
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
  }

  .body_cupon{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
  }

  .cupon_information{
    display: grid;
    gap: 5px;
  }
  .cupon {
    font-size: 16px;
    font-weight: var(--regular);
  }

  .expira{
    color: var(--primary);
    font-size: 14px;
  }

  .code{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
  }

  @media only screen and (min-width: 768px){
    .item_cupon,
    .body_cupon{
      flex-direction: row;
      align-items: center;
    }
  }
</style>

