<template>
  <div class="order_status_component_wrapper">

    <div class="rastrea_proceso_step_indicator">
      <div class="step"
      >

        <div class="step-icon"
        >
          <Vue3Lottie
              ref="pending_anim"
              :animationData=pending_order_json
              :style="pending_playing? 'opacity:1' : 'opacity:0.6'"
              :height="55" :width="55"
              :autoPlay="pending_playing"
          />
        </div>

      </div>
      <div class="step"
           :style="first_step? 'opacity: 1': 'opacity: 0.6'"
      >
        <div class="step-icon" >
          <Vue3Lottie
              ref="cooking_anim"
              :animationData=cooking_order_json
              :height="55" :width="55"
              :style="first_playing? 'opacity:1' : 'opacity:0.6'"
              :autoPlay="first_playing"
              style="transform: scale(1.2)"
          />

        </div>

      </div>

      <div class="step"
           :style="second_step? 'opacity: 1': 'opacity: 0.6'"
      >
        <div class="step-icon">
          <Vue3Lottie
              ref="takeout_anim"
              v-if="item.type_delivery === '2' || item.type_delivery === '4'"
              :animationData=takeout_json
              :height="55" :width="55"
              :style="second_playing? 'opacity:1' : 'opacity:0.6'"
              :autoPlay="second_playing"
              style="transform: scale(1.2) translateX(15px);"
          />

          <Vue3Lottie
              ref="delivery_anim"
              v-if="item.type_delivery === '3'"
              :animationData=on_way_order_json
              :height="55" :width="55"
              :style="second_playing? 'opacity:1' : 'opacity:0.6'"
              :autoPlay="second_playing"
              style="transform: scale(1.2)"
          />
        </div>

      </div>

      <div class="step"
           :style="third_step? 'opacity: 1': 'opacity: 0.6'"
      >
        <div class="step-icon">
          <Vue3Lottie
              ref="delivered_anim"
              :animationData=delivered_order_json
              :height="55" :width="55"
              :style="third_playing? 'opacity:1' : 'opacity:0.6'"
              :autoPlay="third_playing"
              style="transform: scale(1.8)"
          />
        </div>

      </div>
    </div>
    <div class="status-bar">
      <div class="sections">
        <div class="circle" style="background: #0B994D"></div>
        <div class="bar" :style="first_step? 'background: #0B994D': 'background: var(--complementary-400)'"></div>
      </div>
      <div class="sections">
        <div class="circle" :style="first_step? 'background: #0B994D': 'background: var(--complementary-400)'"></div>
          <div class="bar" :style="second_step? 'background: #0B994D': 'background: var(--complementary-400)'"></div>
      </div>
      <div class="sections">
        <div class="circle" :style="second_step? 'background: #0B994D': 'background: var(--complementary-400)'"></div>
        <div class="bar" :style="third_step? 'background: #0B994D': 'background: var(--complementary-400)'"></div>
      </div>
      <div class="sections">
        <div class="circle" :style="third_step? 'background: #0B994D': 'background: var(--complementary-400)'"></div>
      </div>
    </div>
    <div class="rastrea_proceso_step_indicator">
      <div class="step"
           :style="pending_playing? 'opacity: 1': 'opacity: 0.6'"
      >
        <p class="status_name">Orden realizada</p>
        <p class="status_name">{{getFormattedTime(item.order_date)}}</p>
      </div>
      <div class="step"
           :style="first_playing? 'opacity: 1': 'opacity: 0.6'"
      >
        <p class="status_name">{{ $t('ordenstatuscomponent.txt_cocinando_tu_orden') }}</p>
        <p class="status_name" v-if="first_step && item.time_cooking_started != null">{{getFormattedTime(item.time_cooking_started)}}</p>
      </div>

      <div class="step"
           :style="second_playing? 'opacity: 1': 'opacity: 0.6'"
      >
        <p class="status_name" v-if="item.type_delivery === '2' || item.type_delivery === '4'">{{ $t('ordenstatuscomponent.txt_listo_para_recojo') }}</p>
        <p class="status_name" v-if="item.type_delivery === '3'">{{ $t('ordenstatuscomponent.txt_en_camino') }}</p>
        <div v-if="second_step && item.time_last_step != null">{{getFormattedTime(item.time_last_step)}}</div>
      </div>

      <div class="step"
           :style="third_playing? 'opacity: 1': 'opacity: 0.6'"
      >
        <p class="status_name">{{ $t('ordenstatuscomponent.txt_entregado') }}</p>
        <p class="status_name" v-if="third_step && item.real_waiting_time !== 0">{{getTotalOrderTime(item.order_date, item.real_waiting_time)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {Vue3Lottie} from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import pending_order_json from '@/assets/animationViews/pendingAnimation.json';
import cooking_order_json from '@/assets/animationViews/orderCookingAnimation.json';
import on_way_order_json from '@/assets/animationViews/onWayAnimation.json';
import takeout_json from '@/assets/animationViews/orderReadyTakeoutAnimation.json';
import delivered_order_json from '@/assets/animationViews/deliveredAnimation.json';

export default {
  name: "OrderStatusComponent",
  props: ['item'],
  components: {
    Vue3Lottie,
  },
  data () {
    return {
      colorPrincipal: 'var(--primary)',
      colorPrincipalTransparencia: '#D3D3D3',
      pending_order_json,
      cooking_order_json,
      on_way_order_json,
      takeout_json,
      delivered_order_json,
      first_step: true,
      second_step: false,
      third_step: false,
      pending_playing: false,
      first_playing: false,
      second_playing: false,
      third_playing: false
    }
  },
  mounted() {

    this.updateBarByOrderStatus(this.item.status)
  },
  methods: {

    getTotalOrderTime(initial_time, minutes) {
      return moment(initial_time).add(minutes, 'minutes').format('hh:mm a')
    },

    getFormattedTime(time) {
      return moment(time).format('hh:mm a')
    },

    resetAnimsAndColors() {

      this.first_step = false
      this.second_step = false
      this.third_step = false
      this.pending_playing = false
      this.first_playing = false
      this.second_playing = false
      this.third_playing = false
    },

    updateBarByOrderStatus(status) {
      this.resetAnimsAndColors()
      switch (status) {
        case '0':
          this.pending_playing = true
              break
        case '1':
          console.log("status 1")
          this.first_step = true
          this.first_playing = true
          break
        case '2':
          console.log("status 2 ")
            this.first_step = true
            this.second_step = true
          this.second_playing = true

          break
        case '3':
          console.log("status 3 ")

          break
        case '4':
          console.log("status 4 ")
            this.first_step = true
            this.second_step = true
            this.third_step = true
            this.third_playing = true

          break
        case '5':
          this.first_step = true
          this.second_step = true
          console.log("status 5 ")

          break
        case '6':

          this.first_step = true
          this.second_step = true
          this.second_playing = true
              break
      }


    },

    getAmOrPm (hour) {
      return moment(hour, 'HH:mm').format('hh:mm a')
    },
  },

  watch: {
  }
}
</script>

<style scoped>
  .status_name{
    font-size: 14px;
    font-weight: var(--regular);
  }


  .status-bar {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
  }

  .sections {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #00FF00;
    z-index: 2;
    transition: 0.3s ease all;
  }

  .bar {
    position: absolute;
    bottom: 33%;
    left: 65%;
    width: 70%;
    height: 5px;
    background-color: gray;
    transition: 0.3s ease all;
  }


  .order_status_component_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .rastrea_proceso_step_indicator {
    display: flex;
    padding: 0 0;
  }


  .step {
    width: 25%;
    text-align: center;
    transition: 0.3s ease all;
    font-weight: 400;
    color:#000000;
    margin-bottom: 0;
    font-size: 14px;
    margin-top: 5px;
  }

  .step-icon {
    margin: 0 0;
    padding: 0 0;
    z-index: 10;
  }

  @media only screen and (min-width: 768px) {
    .bar {
      left: 60%;
      width: 80%;
      border-radius: 8px;
    }

  }
</style>